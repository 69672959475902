import React from 'react';
import styled from 'styled-components';
import { SecondaryP } from './Typography';
import TickIcon from '../assets/icons/tickIcon.svg';

const Wrapper = styled.div`
  display: flex;
  position: relative;
  flex-basis: 25%;
  flex-grow: 1;
  background-color: #ffffff;
  border: 1px solid #c4dbf0;
  box-shadow: 0px 12px 30px rgba(129, 129, 165, 0.2);
  border-radius: 10px;
  padding: 2rem;
`;

const Tick = styled(TickIcon)`
  position: absolute;
  left: -11px;
`;

const StyledSecondaryP = styled(SecondaryP)`
  color: #495b6c;
`;

interface TickedCardProps {
  text: string;
}

const TickedCard: React.FunctionComponent<TickedCardProps> = ({ text }) => (
  <Wrapper>
    <Tick />
    <StyledSecondaryP>{text}</StyledSecondaryP>
  </Wrapper>
);

export default TickedCard;
