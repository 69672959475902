import React from 'react';
import styled from 'styled-components';
import { Body4Bold, P, Subtitle5 } from './Typography';

const Wrapper = styled.div<{ flexBasis?: string; marginBottom?: string }>`
  flex-basis: ${({ flexBasis }) => (flexBasis ? `${flexBasis}` : `30%`)};
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  background-color: #ffffff;
  border-radius: 10px;
  padding: 1rem;
  flex-grow: 1;
  box-shadow: 0px 12px 30px rgba(129, 129, 165, 0.2);
  ${({ marginBottom }) => marginBottom && `margin-bottom: ${marginBottom}`};
  border: 1px solid #dbe3eb;
  cursor: pointer;
  transition: 0.4s;
  @media (max-width: 1200px) {
    flex-grow: 1;
    margin-bottom: 2rem;
  }
`;
const Img = styled.img<{ imgMarginBottom: string }>`
  width: 70px;
  height: 70px;
  margin-bottom: ${(props) =>
    props.imgMarginBottom ? `${props.imgMarginBottom}` : `1rem`};
`;
const Info = styled.div`
  min-height: 10rem;
`;
const Title = styled(Subtitle5)`
  margin-bottom: 0.5rem;
`;

const LearnMore = styled.a`
  text-transform: underline;
  color: #13273f;
`;

const StyledBody4Bold = styled(Body4Bold)`
  margin-top: 1.5rem;
`;
interface SmallCardProps {
  title: string;
  text: string;
  learnMore?: string;
  cardImgSrc: string;
  flexBasis?: string;
  marginBottom?: string;
  imgMarginBottom?: string;
}
const SmallCard: React.FunctionComponent<SmallCardProps> = ({
  title,
  text,
  learnMore,
  cardImgSrc,
  flexBasis,
  marginBottom,
  imgMarginBottom,
}) => (
  <Wrapper flexBasis={flexBasis} marginBottom={marginBottom}>
    <Img imgMarginBottom={imgMarginBottom} src={cardImgSrc} alt="card logo" />
    <Info>
      <Title>{title}</Title>
      <P>{text}</P>
      {learnMore && (
        <LearnMore href={learnMore}>
          <StyledBody4Bold>Learn more</StyledBody4Bold>
        </LearnMore>
      )}
    </Info>
  </Wrapper>
);

export default SmallCard;
