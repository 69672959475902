import React from 'react';
import styled from 'styled-components';
import { Description, H1, H3, P, SecondaryP } from '@/components/Typography';
import ButtonDefault from '@/components/ButtonDefault';
import viewport1Img from '../../assets/img/productHubVp1.png';
// import viewport3Img from '../../assets/img/toolkitHomeVp6.png';
import viewport5Img from '../../assets/img/toolkitHomeVp5.png';
import viewport7Img from '../../assets/img/toolkitHomeVp7.png';
import viewport9Img from '../../assets/img/toolkitHomeVp8.png';
import viewport10Img from '../../assets/img/productHubVp10.png';
import viewport10BgImg from '../../assets/img/productHubVp10bg.png';
import viewport11Img from '../../assets/img/toolkitHomeVp4.png';
import bannerBg from '../../assets/img/productHubBannerBg.png';
import Section from '@/components/Section';
import FirstViewport from '@/components/FirstViewport';
import TickedItems from '@/components/TickedItems';
import SmallCard from '@/components/SmallCard';
import FirstViewportMirrored from '@/components/FirstViewportMirrored';
import CarouselCard from '@/components/CarouselCard';
import TickedCard from '@/components/TickedCard';
import HowItWorksCard from '@/components/HowItWorksCard';
import Banner from '@/components/Banner';
import { ProductHubPageContent } from '@/pages/product-inventory';

const FirstVpWrapper = styled.div`
  background: linear-gradient(118.47deg, #f8f9fa 3.57%, #e4e6f0 100%);
`;

const SecondViewport = styled(Section)`
  background: linear-gradient(180deg, #ffffff 0%, #e7edf3 100%);
  @media (max-width: 1200px) {
    padding-bottom: 1rem;
  }
`;

const WhiteBgViewportWrapper = styled.div`
  background: #fff;
`;

// const ThirdViewport = styled(FirstViewportMirrored)`
//   background: #fff;
// `;

const FourthViewport = styled(Section)``;

const FifthViewport = styled(FirstViewport)``;

const SeventhViewport = styled(FirstViewport)``;

const SixthViewportWrapper = styled.div`
  background: linear-gradient(358.95deg, #f4f7f9 1.49%, #e7edf3 97.48%);
  transform: rotate(180deg);
`;

const SixthViewport = styled(Section)`
  transform: rotate(-180deg);
`;

const Vp8Wrapper = styled.div`
  background: linear-gradient(
    358.95deg,
    #e7edf3 1.49%,
    rgba(255, 255, 255, 5) 97.48%
  );
`;

const EighthViewport = styled(Section)``;

const NinthViewport = styled(Section)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: nowrap;
  height: calc(100vh - 70px - 5rem * 2);
  padding-top: 0;
  padding-bottom: 0;
  @media (max-width: 1200px) {
    flex-wrap: wrap;
    height: auto;
  }
`;

const TenthViewportWrapper = styled.div`
  background-image: ${(props) => `url(${props.resource})`};
  background-repeat: no-repeat;
  background-size: cover;
`;

const TenthViewport = styled(FirstViewport)``;

const EleventhViewport = styled(FirstViewportMirrored)``;

const TwelfthViewport = styled(Section)``;

const StyledDescription = styled(Description)`
  font-weight: 500;
`;

const StyledButtonDefault = styled(ButtonDefault)`
  margin-top: 2rem;
`;

const StyledSecondaryP = styled(SecondaryP)`
  margin-bottom: 1rem;
`;

const CenteredH3 = styled(H3)`
  text-align: center;
`;

const BenefitCardWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-top: 2rem;
  gap: 1rem;
`;

const Vp2Description = styled(P)`
  margin-top: 0.5rem;
  width: 80%;
`;

const DescriptionWrapper = styled.div`
  margin: 1rem 0;
`;

const CardWrapper = styled.div`
  display: flex;
  margin-top: 3rem;
  flex-wrap: wrap;
  @media (max-width: 1200px) {
    flex-basis: 100%;
    flex-wrap: wrap;
  }
`;

const TickedCardsWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 3rem;
  margin-top: 2rem;
`;

const Vp8CardsWrapper = styled.div`
  display: flex;
  margin-top: 2rem;
  flex-wrap: nowrap;
  @media (max-width: 1200px) {
    flex-wrap: wrap;
  }
`;

const RightSideContent = styled.div`
  flex: 1 1 30%;
  padding-left: 2rem;
  @media (max-width: 1200px) {
    padding-left: 0;
  }
`;

const LeftImage = styled.img`
  max-height: 100%;
  width: 50%;

  @media (max-width: 1200px) {
    max-height: auto;
    margin-bottom: 5rem;
    margin-top: 2rem;
    padding-left: 0;
  }
`;

interface ProductHubProps {
  content: ProductHubPageContent;
}

const PeroductHubContainer: React.FunctionComponent<ProductHubProps> = ({
  content: {
    viewport1,
    viewport2,
    // viewport3,
    viewport4,
    viewport5,
    viewport6,
    viewport7,
    viewport8,
    viewport9,
    viewport10,
    viewport11,
    viewport12,
  },
}) => (
  <>
    <FirstVpWrapper>
      <FirstViewport img={viewport1Img}>
        <H1>
          {viewport1.title1}
          {` `}
          <span className="accent-text">{viewport1.title2}</span>
          {` `}
        </H1>
        <StyledDescription>{viewport1.description}</StyledDescription>
        <TickedItems items={viewport1.tickedDescription} />
        <StyledButtonDefault to={viewport1.button.url}>
          {viewport1.button.label}
        </StyledButtonDefault>
      </FirstViewport>
    </FirstVpWrapper>

    <SecondViewport>
      <H1>{viewport2.title}</H1>
      <Vp2Description>{viewport2.description}</Vp2Description>
      <BenefitCardWrapper>
        {viewport2.benefitCards.map((item) => (
          <SmallCard
            key={item.title}
            title={item.title}
            text={item.description}
            cardImgSrc={item.imageSrc}
            flexBasis="12%"
          />
        ))}
      </BenefitCardWrapper>
    </SecondViewport>

    {/* <WhiteBgViewportWrapper>
      <ThirdViewport img={viewport3Img} withRightPadding imgWidth="50%">
        <StyledSecondaryP className="accent-text-gray">
          {viewport3.label}
        </StyledSecondaryP>
        <H1>{viewport3.title}</H1>
        <DescriptionWrapper>
          <TickedItems items={viewport3.descriptionItems} />
        </DescriptionWrapper>
      </ThirdViewport>
    </WhiteBgViewportWrapper> */}

    <FourthViewport>
      <CenteredH3>{viewport4.title}</CenteredH3>
      <CardWrapper>
        {viewport4.benefitCards.map((item) => (
          <CarouselCard
            key={item.title}
            flexBasis="25%"
            marginBottom="2rem"
            title={item.title}
            text={item.description}
            cardImgSrc={item.imageSrc}
          />
        ))}
      </CardWrapper>
    </FourthViewport>

    <WhiteBgViewportWrapper>
      <FifthViewport img={viewport5Img} withRightPadding imgWidth="50%">
        <StyledSecondaryP className="accent-text-gray">
          {viewport5.label}
        </StyledSecondaryP>
        <H1>{viewport5.title}</H1>
        <DescriptionWrapper>
          <TickedItems items={viewport5.descriptionItems} />
        </DescriptionWrapper>
      </FifthViewport>
    </WhiteBgViewportWrapper>

    <SixthViewportWrapper>
      <SixthViewport>
        <CenteredH3>{viewport6.title}</CenteredH3>
        <TickedCardsWrapper>
          {viewport6.tickedItems.map((item) => (
            <TickedCard key={item.item} text={item.item} />
          ))}
        </TickedCardsWrapper>
      </SixthViewport>
    </SixthViewportWrapper>

    <WhiteBgViewportWrapper>
      <SeventhViewport img={viewport7Img} withRightPadding imgWidth="50%">
        <StyledSecondaryP className="accent-text-gray">
          {viewport7.label}
        </StyledSecondaryP>
        <H1>{viewport7.title}</H1>
        <DescriptionWrapper>
          <TickedItems items={viewport7.descriptionItems} />
        </DescriptionWrapper>
      </SeventhViewport>
    </WhiteBgViewportWrapper>

    <Vp8Wrapper>
      <EighthViewport>
        <CenteredH3>{viewport8.title}</CenteredH3>
        <Vp8CardsWrapper>
          {viewport8.viewport8Cards.map((item) => (
            <HowItWorksCard
              key={item.title}
              title={item.title}
              text={item.description}
              image={item.imageSrc}
              imageAlignBottomLeft
              allImgCentered
              backgroundColor="#fff"
            />
          ))}
        </Vp8CardsWrapper>
      </EighthViewport>
    </Vp8Wrapper>

    <WhiteBgViewportWrapper>
      <NinthViewport>
        <LeftImage src={viewport9Img} alt="application screnshot" />
        <RightSideContent>
          <StyledSecondaryP className="accent-text-gray">
            {viewport9.label}
          </StyledSecondaryP>
          <H1>{viewport9.title}</H1>
          <DescriptionWrapper>
            <TickedItems items={viewport9.descriptionItems} />
          </DescriptionWrapper>
        </RightSideContent>
      </NinthViewport>
    </WhiteBgViewportWrapper>

    <TenthViewportWrapper resource={viewport10BgImg}>
      <TenthViewport img={viewport10Img} imgWidth="50%" withRightPadding>
        <StyledSecondaryP className="accent-text">
          {viewport10.label}
        </StyledSecondaryP>
        <H1>{viewport10.title}</H1>
        <DescriptionWrapper>
          <TickedItems items={viewport10.descriptionItems} />
        </DescriptionWrapper>
      </TenthViewport>
    </TenthViewportWrapper>

    <WhiteBgViewportWrapper>
      <EleventhViewport img={viewport11Img} withRightPadding imgWidth="50%">
        <StyledSecondaryP className="accent-text-gray">
          {viewport11.label}
        </StyledSecondaryP>
        <H1>{viewport11.title}</H1>
        <DescriptionWrapper>
          <TickedItems items={viewport11.descriptionItems} />
        </DescriptionWrapper>
      </EleventhViewport>

      <TwelfthViewport>
        <Banner
          bgSrc={bannerBg}
          label={viewport12.bannerLabel}
          descriptionItems={viewport12.descriptionItems}
        />
      </TwelfthViewport>
    </WhiteBgViewportWrapper>
  </>
);

export default PeroductHubContainer;
