import React from 'react';
import Main from '../containers/Layout';
import productHubContent from '../../content/pages/product-inventory.yml';
import PeroductHubContainer from '@/containers/toolkit/ProductHubContainer';

export interface ProductHubPageContent {
  viewport1: {
    title1: string;
    title2: string;
    description: string;
    tickedDescription: { item: string }[];
    button: {
      label: string;
      url: string;
    };
  };
  viewport2: {
    title: string;
    description: string;
    benefitCards: {
      title: string;
      description: string;
      imageSrc: string;
    }[];
  };
  viewport3: {
    label: string;
    title: string;
    descriptionItems: {
      item: string;
    }[];
  };
  viewport4: {
    title: string;
    benefitCards: {
      title: string;
      description: string;
      imageSrc: string;
    }[];
  };
  viewport5: {
    label: string;
    title: string;
    descriptionItems: {
      item: string;
    }[];
  };
  viewport6: {
    title: string;
    tickedItems: {
      item: string;
    }[];
  };
  viewport7: {
    label: string;
    title: string;
    descriptionItems: {
      item: string;
    }[];
  };
  viewport8: {
    title: string;
    viewport8Cards: {
      title: string;
      description: string;
      imageSrc: string;
    }[];
  };
  viewport9: {
    label: string;
    title: string;
    descriptionItems: {
      item: string;
    }[];
  };
  viewport10: {
    label: string;
    title: string;
    descriptionItems: {
      item: string;
    }[];
  };
  viewport11: {
    label: string;
    title: string;
    descriptionItems: {
      item: string;
    }[];
  };

  viewport12: {
    bannerLabel: string;
    descriptionItems: {
      item: string;
    }[];
    button: { label: string; url: string };
  };
}

const ProductHub: React.FunctionComponent = () => {
  const {
    viewport1,
    viewport2,
    viewport3,
    viewport4,
    viewport5,
    viewport6,
    viewport7,
    viewport8,
    viewport9,
    viewport10,
    viewport11,
    viewport12,
  } = productHubContent as unknown as ProductHubPageContent;
  return (
    <Main>
      <PeroductHubContainer
        content={{
          viewport1,
          viewport2,
          viewport3,
          viewport4,
          viewport5,
          viewport6,
          viewport7,
          viewport8,
          viewport9,
          viewport10,
          viewport11,
          viewport12,
        }}
      />
    </Main>
  );
};

export default ProductHub;
